import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import EditorNextGen from 'editorNextGen';
import { useSimplifiedView } from 'templates/useSimplifiedView';
const ChooseEditor = (props) => {
    var _a;
    const history = useHistory();
    const params = useParams();
    const simplifiedView = useSimplifiedView();
    // Handle both editor opening methods (directly from URL/:id) 
    // and by previous location state resolution 
    // DEPRECATED (TBD 3.36.x, Remove old "location.state" options)
    const assignmentId = params.id || ((_a = history.location.state) === null || _a === void 0 ? void 0 : _a.id);
    const assignemntProps = { ...props };
    assignemntProps.location.state = { id: assignmentId };
    return React.createElement(EditorNextGen, { assignmentId: assignmentId, simplifiedView: simplifiedView, ...props });
};
export default ChooseEditor;
