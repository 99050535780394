import React, { useState } from 'react';
import { Button, Dropdown, Icon, Modal, Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { Styled } from 'StyledComponents';
import PagedAssignmentsExecutorView from '../PagedAssignmentsExecutorView';
import TablePlaceholder from 'helpers/components/TablePlaceholder';
import { getAssignments, getAssignmentsCsv, perspectiveToStageList } from 'api-operations/executor/executor';
import { toast } from 'react-toastify';
import Summary from 'dashboards/shared/Summary';
import { useLogger } from 'logger/Logger';
import FinishedAssignmentsRow from './FinishedAssignmentsRow';
const FinishedAssignments = ({ perspective }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [assignments, setAssignments] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isSummaryModalOpen, setIsSummaryModalOpen] = useState(false);
    const [allResultsCount, setAllResultsCount] = useState(0);
    const [paginationOptions, setPaginationOptions] = useState({});
    const { t } = useTranslation('common');
    const { logger } = useLogger();
    const fetchData = async (paginationFilterOptions) => {
        if (document.visibilityState == "hidden")
            return;
        if (isError) {
            setIsError(false);
            setIsLoading(true);
        }
        try {
            const response = await getAssignments(perspectiveToStageList(perspective), 'FINISHED', paginationFilterOptions);
            setAssignments(response.results);
            setAllResultsCount(response.totalCount);
        }
        catch (error) {
            setIsError(true);
            logger.error('System - Failed to fetch delivered assignments', error);
        }
        setIsLoading(false);
    };
    const downloadCsv = async () => {
        try {
            await getAssignmentsCsv(perspectiveToStageList(perspective), paginationOptions);
            logger.info('User - Downloaded assignments CSV');
        }
        catch (error) {
            toast.error(t('notification.error'));
            logger.error('User - Failed to download assignments CSV', error);
        }
    };
    const orderOptions = [
        { key: 'DUD', text: t('pagination.filter.uploadDateD'), value: 'uploadDate DESCENDING' },
        { key: 'AUD', text: t('pagination.filter.uploadDateA'), value: 'uploadDate ASCENDING' },
        { key: 'FD', text: t('pagination.filter.fileNameD'), value: 'fileName DESCENDING' },
        { key: 'FA', text: t('pagination.filter.fileNameA'), value: 'fileName ASCENDING' },
        { key: 'LFD', text: t('pagination.filter.langFromD'), value: 'langFrom DESCENDING' },
        { key: 'LFA', text: t('pagination.filter.langFromA'), value: 'langFrom ASCENDING' },
        { key: 'TFD', text: t('pagination.filter.langToD'), value: 'langTo DESCENDING' },
        { key: 'TFA', text: t('pagination.filter.langToA'), value: 'langTo ASCENDING' }
    ];
    return React.createElement(React.Fragment, null,
        React.createElement(Styled.H3, null, t('dashboard.finishedJobs')),
        React.createElement(PagedAssignmentsExecutorView, { orderOptions: orderOptions, totalResults: allResultsCount, fetchData: fetchData, interval: 20000, key: perspective, tableIdentifier: `${perspective}FinishedAssignments`, setPaginationOptions: setPaginationOptions },
            React.createElement(Table, { celled: true, color: "green", selectable: true, striped: true },
                React.createElement(Table.Header, null,
                    React.createElement(Table.Row, null,
                        React.createElement(Table.HeaderCell, null,
                            React.createElement(Dropdown, { as: Button, compact: true, floating: true, color: 'blue', text: 'Actions' },
                                React.createElement(Dropdown.Menu, null,
                                    React.createElement(Dropdown.Item, { onClick: () => downloadCsv() },
                                        React.createElement(Icon, { name: "download" }),
                                        t('dashboard.downloadCsv')),
                                    React.createElement(Dropdown.Item, { onClick: () => setIsSummaryModalOpen(true) },
                                        React.createElement(Icon, { name: "archive" }),
                                        t('dashboard.showSummary'))))),
                        React.createElement(Table.HeaderCell, null, t('dashboard.requester')),
                        React.createElement(Table.HeaderCell, null, t('dashboard.projectName')),
                        React.createElement(Table.HeaderCell, null, t('dashboard.languages')),
                        React.createElement(Table.HeaderCell, null))),
                isLoading || isError || !assignments.length ? React.createElement(TablePlaceholder, { isError: isError, isLoading: isLoading, noEntries: !assignments.length })
                    : React.createElement(Table.Body, null, assignments.map(assignment => React.createElement(FinishedAssignmentsRow, { key: assignment.id, assignment: assignment, perspective: perspective }))))),
        React.createElement(Modal, { open: isSummaryModalOpen, onClose: () => setIsSummaryModalOpen(false) },
            React.createElement(Modal.Content, null,
                React.createElement(Summary, { perspective: perspective, paginationOptions: paginationOptions }))));
};
export default FinishedAssignments;
