import axios from "axios";
import { downloadFileWithName } from "../common";
export const subAssetSubtypeAllValues = new Set(["F_NAME", "M_NAME",
    "SURNAME", "EMAIL_ADDRESS", "NORP", "FAC", "ORG", "GPE", "LOC", "PRODUCT", "EVENT",
    "WORK_OF_ART", "LAW", "LANGUAGE", "DATE", "TIME", "PERCENT", "MONEY", "QUANTITY",
    "ORDINAL", "CARDINAL", "PHONE", "CODE", "POS", "URL", "OTHER", "UNRECOGNIZED", "PERSON"]);
export const subAssetSubtypeAllValuesGrouped = new Set([
    { header: "names", subTypes: ["EVENT", "POS", "LAW", "F_NAME", "M_NAME", "SURNAME", "ORG", "PRODUCT", "WORK_OF_ART"] },
    { header: "locations", subTypes: ["GPE", "EMAIL_ADDRESS", "FAC", "LANGUAGE", "LOC", "URL"] },
    { header: "numbers", subTypes: ["CARDINAL", "DATE", "MONEY", "QUANTITY", "ORDINAL", "PERCENT", "PHONE", "TIME"] },
    { header: "other", subTypes: ["CODE", "UNRECOGNIZED", "OTHER"] }
]);
const RESOURCE_PATH = '/api/assignment/editor';
export async function getAssignmentInfo(assignmentId) {
    const response = await axios.get(`${RESOURCE_PATH}/${assignmentId}`);
    return response.data;
}
export async function getAssignmentViewType(assignmentId) {
    const response = await axios.get(`${RESOURCE_PATH}/${assignmentId}/view-type`);
    return response.data;
}
export async function getAssignmentJobSectionAssets(assignmentId, jobSectionId, offset, limit) {
    const response = await axios.get(`${RESOURCE_PATH}/${assignmentId}/assets`, {
        params: { offset, limit, jobSectionId }
    });
    return response.data;
}
export async function getAssetsFolding(assignmentId, jobSectionId) {
    const response = await axios.get(`${RESOURCE_PATH}/${assignmentId}/assetsFolding`, {
        params: { jobSectionId }
    });
    return response.data;
}
export async function getAssetExtraData(assignmentId, assetId) {
    const response = await axios.get(`${RESOURCE_PATH}/${assignmentId}/asset/${assetId}/extraData`);
    return response.data;
}
export async function getAssetsTerminology(assignmentId, assetIds) {
    const response = await axios.post(`${RESOURCE_PATH}/${assignmentId}/assets-terminology`, (assetIds));
    return response.data;
}
export async function getQaTable() {
    const response = await axios.get(`${RESOURCE_PATH}/qaTable`);
    return response.data;
}
export async function getProgress(assignmentId) {
    const response = await axios.get(`${RESOURCE_PATH}/${assignmentId}/progress`);
    return response.data;
}
export async function getJobSectionProgress(assignmentId, jobSectionId) {
    const response = await axios.get(`${RESOURCE_PATH}/${assignmentId}/progress`, {
        params: { jobSectionId }
    });
    return response.data;
}
export async function refreshAssignmentProgress(assignmentId) {
    const response = await axios.get(`${RESOURCE_PATH}/${assignmentId}/progress/refresh`);
    return response.data;
}
export async function reportWorkSeconds(assignmentId, seconds) {
    await axios.put(`${RESOURCE_PATH}/${assignmentId}/time`, undefined, {
        params: { time: seconds }
    });
}
export async function trackSearch(assignmentId, phrase) {
    const url = new URL(new URL(window.location.href).origin);
    url.pathname = `${RESOURCE_PATH}/${assignmentId}/trackSearch`;
    url.searchParams.append("phrase", phrase);
    await axios.post(url.toString());
}
export async function searchAsset(assignmentId, jobSectionId, q, offset, limit) {
    const response = await axios.get(`${RESOURCE_PATH}/${assignmentId}/search`, {
        params: { q, jobSectionId, offset, limit }
    });
    return response.data;
}
export async function commitAsset(assignmentId, commit) {
    const { assetId, completed, content, subassets } = commit;
    await axios.post(`${RESOURCE_PATH}/${assignmentId}/commit`, { assetId, content, completed, subassets });
}
export async function setAssetError(assignmentId, assetId, error, justification) {
    await axios.put(`${RESOURCE_PATH}/${assignmentId}/asset/${assetId}/error`, error ? { error, justification } : "null");
}
export async function createTerminology(assignmentId, subAsset) {
    await axios.post(`${RESOURCE_PATH}/${assignmentId}/createSubAsset`, subAsset);
}
export async function changeSubAssetSubtypes(subAssetId, subtypes) {
    await axios.post(`${RESOURCE_PATH}/subAsset/${subAssetId}/changeSubtypes`, Array.from(subtypes));
}
export async function finishAssignment(assignmentId) {
    await axios.post(`${RESOURCE_PATH}/${assignmentId}/deliver`);
}
export async function uploadJobSectionRedactedDocument(assignmentId, jobSectionId, file, skipLanguageDetection) {
    const body = new FormData();
    body.append('file', file);
    await axios.post(`${RESOURCE_PATH}/${assignmentId}/uploadOutput`, body, {
        params: { jobSectionId, skipLanguageDetection }
    });
}
export async function downloadSourceFile(assignmentId, jobSectionId, fileName) {
    await downloadFileWithName(`${RESOURCE_PATH}/${assignmentId}/sourceFile`, { jobSectionId }, fileName);
}
export async function changeJobSectionAssetsType(assignmentId, jobSectionId, type, description = null) {
    await axios.post(`${RESOURCE_PATH}/${assignmentId}/subAssets/changeType`, undefined, {
        params: { type, description, jobSectionId }
    });
}
export async function getJobAndStageCommentsForAssignment(assignmentId) {
    const response = await axios.get(`${RESOURCE_PATH}/${assignmentId}/comments`);
    return response.data;
}
export async function generateJobSectionSnapshot(assignmentId, jobSectionId) {
    await axios.post(`${RESOURCE_PATH}/${assignmentId}/snapshot`, undefined, {
        params: { jobSectionId }
    });
}
export async function isJobSectionSnapshotReady(assignmentId, jobSectionId) {
    const response = await axios.head(`${RESOURCE_PATH}/${assignmentId}/snapshot`, {
        params: { jobSectionId }
    });
    return response.status == 200;
}
export async function downloadJobSectionSnapshot(assignmentId, jobSectionId, fileName) {
    await downloadFileWithName(`${RESOURCE_PATH}/${assignmentId}/snapshot`, { jobSectionId }, fileName);
}
export async function getJobSectionNextUncompletedAssetOrder(assignmentId, jobSectionId, offset) {
    const response = await axios.get(`${RESOURCE_PATH}/${assignmentId}/next`, {
        params: { offset, jobSectionId }
    });
    return response.data;
}
export async function getSupersedingAssignment(assignmentId) {
    const response = await axios.get(`${RESOURCE_PATH}/${assignmentId}/supersedingAssignment`);
    return response.data;
}
