import { getAssignmentViewType } from "api-operations/editor/editor";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
export function useSimplifiedView() {
    const [simplified, setSimplified] = useState(true);
    const location = useLocation();
    const editorAssignment = /editor\/([a-f0-9-]*)/;
    useEffect(() => {
        var _a;
        const assignmentId = (_a = location.pathname.match(editorAssignment)) === null || _a === void 0 ? void 0 : _a[1];
        if (assignmentId) {
            getAssignmentViewType(assignmentId)
                .then(simplified => setSimplified(simplified == 'simple'))
                .catch(() => setSimplified(false));
        }
        else {
            setSimplified(false);
        }
    }, [location]);
    return simplified;
}
