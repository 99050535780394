import React from 'react';
import styled from 'styled-components';
import NavBar from 'menu/NavBar';
import { useSimplifiedView } from './useSimplifiedView';
const MainContent = styled.div `
  flex-grow: 1;
  overflow: auto;
  font-family: 'Lato', sans-serif;
  height: 100vh;
`;
const ContentWrapper = styled.div `
  display: flex;
`;
const MainAppTemplate = ({ children }) => {
    const simplifiedView = useSimplifiedView();
    return React.createElement(ContentWrapper, null,
        !simplifiedView && React.createElement(NavBar, null),
        React.createElement(MainContent, null, children));
};
export default MainAppTemplate;
