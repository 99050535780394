import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Icon, Popup, Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Styled } from 'StyledComponents';
import AssignmentInfo from '../AssignmentInfo';
import Avatar from 'helpers/components/Avatar';
import { getAssignmentSections } from 'api-operations/executor/executor';
import JobNamePopup from 'dashboards/shared/JobNamePopup';
import { useLogger } from 'logger/Logger';
import ExpandedSectionTableRow from 'common/ExpandedSectionTableRow';
import ButtonWithPopup from 'dashboards/shared/ButtonWithPopup';
const ActionButtons = styled.div `
  display: flex;
  flex-direction: row;
`;
const FinishedAssignmentsRow = ({ assignment, perspective }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [sections, setSections] = useState([]);
    const { logger } = useLogger();
    const { t } = useTranslation('common');
    const history = useHistory();
    const fetchSectionData = async (assignmentId) => {
        try {
            const response = await getAssignmentSections(assignmentId);
            setSections(response);
            setIsExpanded(prevState => !prevState);
        }
        catch (error) {
            logger.error('System - Failed to fetch sections', error);
        }
    };
    const showEnhancerFeedback = (assignmentId) => {
        logger.info('User - Clicked feedback button');
        history.push({
            pathname: '/enhancer/feedback',
            state: { id: assignmentId }
        });
    };
    const openEditor = (perspective, assignmentId) => {
        history.push({
            pathname: `/editor/${assignmentId}`,
            state: { id: assignmentId }
        });
    };
    const openInEditor = (assignmentId) => {
        logger.info(`User - Open editor: ${assignmentId}`);
        openEditor('', assignmentId);
    };
    const accessExpired = assignment.accessExpired;
    const accessExpiredMessage = t('dashboard.accessExpiredMessage');
    return React.createElement(React.Fragment, null,
        React.createElement(Table.Row, { key: assignment.id, "data-assignment-id": assignment.id },
            React.createElement(Table.Cell, null,
                React.createElement(Button, { size: "mini", icon: isExpanded ? "angle up" : "angle down", onClick: () => { fetchSectionData(assignment.id); } })),
            React.createElement(Table.Cell, null,
                React.createElement(Avatar, { name: assignment.assigner, inlineAvatar: true }),
                assignment.assigner),
            React.createElement(Table.Cell, { width: "1" },
                React.createElement(JobNamePopup, { name: assignment.jobName, charityMode: assignment.charity, widthInRem: 22 })),
            React.createElement(Table.Cell, null,
                t(`languages.${(assignment.jobLangFrom.toLowerCase())}`),
                " ",
                React.createElement(Icon, { name: "arrow right" }),
                " ",
                t(`languages.${assignment.jobLangTo.toLowerCase()}`)),
            React.createElement(Table.Cell, null,
                React.createElement(ActionButtons, null,
                    perspective === 'enhancer' && React.createElement(ButtonWithPopup, { label: t('enhancerFeedback.enhancerFeedback'), labelWhenDisabled: accessExpiredMessage, icon: "external alternate", disabled: accessExpired, onClick: () => showEnhancerFeedback(assignment.id), color: "blue" }),
                    assignment.verificationAssignmentId && React.createElement(ButtonWithPopup, { label: t('dashboard.viewTrustMinedVersion'), labelWhenDisabled: accessExpiredMessage, icon: 'file', disabled: accessExpired, onClick: () => openInEditor(assignment.verificationAssignmentId) }),
                    React.createElement(Popup, { flowing: true, hoverable: true, basic: true, position: "top center", key: assignment.id, trigger: (React.createElement(Button, { icon: "info" })), offset: [0, 0] },
                        React.createElement(Styled.VerticalPopupContent, null,
                            React.createElement(AssignmentInfo, { assignment: assignment, status: "FINISHED" }))),
                    React.createElement(ButtonWithPopup, { label: t('dashboard.showInEditor'), labelWhenDisabled: accessExpiredMessage, icon: 'eye', disabled: accessExpired, onClick: () => openInEditor(assignment.id) })))),
        isExpanded && React.createElement(ExpandedSectionTableRow, { sections: sections }));
};
export default FinishedAssignmentsRow;
