import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { getAssignments } from 'api-operations/executor/executor';
import { getMyAssignmentsInProgress } from 'api-operations/requester/assignment';
import Assignment from './Assignment';
import { useLogger } from 'logger/Logger';
const FETCH_INTERVAL = 20000;
const AssignmentsWrapper = styled.div `
  display: flex;
  gap: 16px;
  padding: 10px;
  overflow: auto;
`;
// Temporary, until we get rid of perspective and merge executors' dashbords into one.
export function perspectiveToStageList(perspective) {
    switch (perspective) {
        case 'enhancer': return ['ENHANCEMENT', 'CORRECTION'];
        case 'trustminer': return ['TRUST_MINING', 'CORRECTION'];
        case 'redactor': return ['REDACTION', 'FINAL_REDACTION', 'CORRECTION'];
        default: return ['CORRECTION'];
    }
}
const Assignments = ({ perspective }) => {
    const [assignments, setAssignments] = useState([]);
    const intervalId = useRef();
    const { logger } = useLogger();
    useEffect(() => {
        fetchAssignmentsData();
        intervalId.current = setInterval(async () => {
            if (document.visibilityState == "hidden")
                return;
            await fetchAssignmentsData();
        }, FETCH_INTERVAL);
        return () => {
            clearInterval(intervalId.current);
        };
    }, [perspective]);
    const fetchAssignmentsData = useCallback(async () => {
        try {
            if (perspective === 'requester') {
                const response = await getMyAssignmentsInProgress();
                setAssignments(response);
            }
            else {
                const response = await getAssignments(perspectiveToStageList(perspective), 'ACCEPTED', { limit: 40 });
                setAssignments(response.results);
            }
        }
        catch (error) {
            logger.error('System - Failed to fetch in progress assets', error);
        }
    }, []);
    return React.createElement(AssignmentsWrapper, null, assignments.map(assignment => React.createElement(Assignment, { key: assignment.id, assignment: assignment, refresh: fetchAssignmentsData })));
};
export default Assignments;
